var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('static-fullscreen-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createNew()}}},[_vm._v("Создать задачу")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.onChangeView()}}},[(_vm.view == 1)?_c('btn-title',{attrs:{"icon":"fas fa-columns"}},[_vm._v("Вид канбан")]):_c('btn-title',{attrs:{"icon":"fas fa-list"}},[_vm._v("Вид таблица")])],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateData()}}},[_c('btn-title',{attrs:{"icon":"fas fa-sync-alt"}},[_vm._v("Обновить")])],1),_c('portal',{attrs:{"to":"v-main"}},[_c('task-dialog',{attrs:{"id":_vm.idEdit},model:{value:(_vm.showTaskDialog),callback:function ($$v) {_vm.showTaskDialog=$$v},expression:"showTaskDialog"}})],1)]},proxy:true}])},[(_vm.view == 1)?_c('a-table-f-api',{ref:"table",attrs:{"api":_vm.url,"model":_vm.model,"useQuery":true,"defaults":_vm.defaults},scopedSlots:_vm._u([{key:"item.is_show_main",fn:function(ref){
var item = ref.item;
var row = ref.row;
var field = ref.field;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.updateTask(row)}},model:{value:(row.is_show_main),callback:function ($$v) {_vm.$set(row, "is_show_main", $$v)},expression:"row.is_show_main"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var row = ref.row;
var field = ref.field;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","fab":"","color":"primary","title":"Редактировать"},on:{"click":function($event){return _vm.showTaskDialogFun(row.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-pen")])],1)],1)]}}],null,false,538167228)}):_vm._e(),(_vm.view == 2)?_c('task-canban',{ref:"taskCanban",attrs:{"api":_vm.url,"model":_vm.model},on:{"show-card":function($event){_vm.idEdit = $event;
      _vm.showTaskDialog = true;}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }