<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="createNew()">Создать задачу</v-btn>
      <v-btn color="primary" @click="onChangeView()">
        <btn-title v-if="view == 1" icon="fas fa-columns">Вид канбан</btn-title>
        <btn-title v-else icon="fas fa-list">Вид таблица</btn-title>
      </v-btn>
      <v-btn color="primary" @click="updateData()"
        ><btn-title icon="fas fa-sync-alt">Обновить</btn-title>
      </v-btn>
      <portal to="v-main">
        <task-dialog v-model="showTaskDialog" :id="idEdit"></task-dialog>
      </portal>
    </template>
    <a-table-f-api
      v-if="view == 1"
      ref="table"
      :api="url"
      :model="model"
      :useQuery="true"
      :defaults="defaults"
    >
      <template v-slot:item.is_show_main="{ item, row, field }">
        <v-checkbox
          v-model="row.is_show_main"
          @change="updateTask(row)"
        ></v-checkbox>
      </template>
      <template v-slot:item.actions="{ item, row, field }">
        <div class="d-flex">
          <v-btn
            small
            fab
            class="ma-1"
            color="primary"
            title="Редактировать"
            @click="showTaskDialogFun(row.id)"
            ><v-icon small>fas fa-pen</v-icon></v-btn
          >
        </div>
      </template>
    </a-table-f-api>
    <task-canban
      ref="taskCanban"
      v-if="view == 2"
      :api="url"
      :model="model"
      @show-card="
        idEdit = $event;
        showTaskDialog = true;
      "
    />
  </static-fullscreen-card>
</template>

<script>
import TaskCanban from "./dialogs/taskCanban.vue";
import taskDialog from "./dialogs/taskDialog.vue";

export default {
  components: {
    TaskCanban,
    taskDialog,
  },
  data() {
    return {
      view: 1,
      idEdit: 0,
      showTaskDialog: false,
      model: this.$store.getters["config/get"].models.tasks.list.concat([
        {
          name: "actions",
          width: 100,
        },
      ]),
      url: "/kazan/tasks",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Задачи";
  },
  watch: {
    showTaskDialog() {
      if (!this.showTaskDialog) {
        this.updateData();
      }
    },
  },
  methods: {
    updateData() {
      if (this.view == 1) {
        this.$refs.table.updateData();
      } else if (this.view == 2) {
        this.$refs.taskCanban.initData();
      }
    },
    onChangeView() {
      if (this.view == 1) {
        this.view = 2;
      } else {
        this.view = 1;
      }
    },
    onClickRow(d) {
      this.showTaskDialogFun(d.row.id);
    },
    createNew() {
      this.showTaskDialogFun(0);
    },
    showTaskDialogFun(id) {
      this.idEdit = id;
      this.showTaskDialog = true;
    },
    async updateTask(row) {
      await this.$axios.post(this.url, row);
      this.$root.$emit("show-info", {
        text: "Данные записаны",
      });
    },
  },
};
</script>

